<template>
  <div data-cy="page-plan">
    <div
      class="header-text pb-3"
      ref="headerPlan"
      aria-labelledby="header for Plan (PPDP) page"
      data-cy="header-plan"
    >
      Plan (PPDP)
    </div>
    <div class="subhead-text pb-5">
      Draft and manage your written plan for learning and developing your
      professional identity as a lawyer.
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 grid-rows-1 gap-8 pb-5">
      <div class="box-style col-span-1 md:col-span-2">
        <div class="secondhead-text">Prepare your plan</div>
        <div class="text-xl pb-7">
          Keep in mind who you want to be as a lawyer.
        </div>
        <HelpChips :chips="plan_chips" />
      </div>
      <div class="box-style col-span-1 md:mb-32">
        <div class="secondhead-text">Need help?</div>
        <div class="text-xl">
          Refer to the
          <a
            href="https://one.stthomas.edu/sites/schools-colleges/school-of-law/SitePage/33159/mentor-externship"
            target="_blank"
            class="text-purple"
            >Mentor X Program Policy Manual</a
          >
          for guidance and sample plans!
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 grid-rows-1 gap-8 pb-5">
      <PlanProgress class="col-span-2 row-span-1" />
      <div class="row-span-1 col-span-1"></div>
    </div>
  </div>
</template>

<script>
import HelpChips from "@/components/displays/student-displays/help/HelpChips";
import PlanProgress from "@/components/displays/student-displays/milestones-plan/PlanProgress";

export default {
  name: "Plan",
  components: { PlanProgress, HelpChips },
  mounted() {
    this.$refs.headerPlan.focus();
  },
  data() {
    return {
      plan_chips: [
        {
          icon: "user-circle",
          title: "Draft Plan",
          description: "Write mission statement and provide details.",
          route: "#",
        },
        {
          icon: "flag",
          title: "Meet Mentor",
          description: "Discuss your plan for the year with your mentor.",
          route: "#",
        },
        {
          icon: "check-circle",
          title: "Finish plan",
          description: "Make any changes and submit your final plan.",
          route: "#",
        },
        {
          icon: "compass",
          title: "Fieldwork Entry",
          description: "Add a PPDP Meeting Entry to your fieldwork logs.",
          route: "#",
        },
      ],
      progress_items: this.$store.state.DEMO_student_progress_items,
    };
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped></style>
